import React from "react";
import HeadBanner from "./components/HeadBanner";
import Nav from "./components/Nav";
import AboutCompany from "./components/AboutCompany";
import Footer from "./components/Footer";
import FooterBanner from "./components/FooterBanner";
import Slider from "./components/Slider";
import Products from "./components/Products";
import Map from "./components/Map";
import Benifits from "./components/Benifits";
import Performer from "./components/Performer";
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import {createBrowserRouter,RouterProvider,Outlet} from 'react-router-dom';


const Layout =()=>{

  return(
    <>
        <HeadBanner/> 
        <hr/>
        <Nav/>

        <Outlet/>
        <Footer/>
        <FooterBanner/>
    </>

  )


}


const router =createBrowserRouter([

  {
    path:"/",
    element:<Layout/>,
    children:[
      {
        path:"/",
        element:<Home/>
      },
      {
        path:"/about-us",
        element:<AboutUs/>
      },
      {
        path:"/contact-us",
        element:<ContactUs/>
      }
    ]
  }


]);



const App = () => {
  return (

    <>
      <RouterProvider router={router}/>
    </>

    // <div className="">
    //   <HeadBanner />
    //   <hr />
    //   <Nav />
    //   <Slider />
    //   <Products />

    //   <AboutCompany />
    //   <Benifits />
    //   <Map />
     
    //   <Performer />
    //   <Footer />
    //   <FooterBanner />
    // </div>
  );
};

export default App;
